.titulo-tab-for{
    margin-left: 150px;
}
.margem-top-fornec{
    margin-top: 115px;
}
.div_regiao_forn{
    max-width: 160px;
}
.div_grupo_forn{
    max-width: 115px;
}
.div_cnpj_forn{
    max-width: 160px;
    font-size: small;
}

.tabela-fornecedores {
    border: 1px solid #d4d4d4;
    border-top: none;
    z-index: 99;
    left: 0;
    right: 0;
    overflow-x: auto !important;
    overflow-y: auto !important;
    max-height: 45vh;
    max-width: 98%;
}
.div-cod-id{
    width: 80px;
}