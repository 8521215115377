.container-fundo-ferias {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 15px;
  background: #3a3d80;
}

.container-body-ferias {
  background-color: white;
  border-radius: 25px;
  width: 100%;
  height: 100%;
}

.calendario-ferias {
  margin-top: 50px;
  min-height: 300px;
  max-height: 500px;
  overflow: auto;
  width: 68%;
}

.h4-ferias{
  position: absolute;
  top: 29px;
  left:25%;
}

.buton-ferias-voltar {
  align-items: center;
  height: 80px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.buton-ferias-listar {
  align-items: center;
  height: 80px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}