.container-fundo-franquias {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 15px;
  background: #3a3d80;
}

.container-body-franquias {
  background-color: white;
  border-radius: 25px;
  width: 100%;
  height: 100%;
margin-left: 2px;
}
.btn_ipr {
  width: 180px;
  height: 50px !important;
}

.container-principal {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 15px;
  background: #3a3d80;
}

.caixa {
  max-width: 50%;
}

.conteudo {

  align-content: flex-end;
}

.rodape {
  max-width: 90%;
  background-color: white;
  border-radius: 30px;
  min-width: 1292px !important;
  margin-left: 20px;
}

.btn-orange {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 30px;
  text-decoration: none;
  background-color: orange;
  color: #3a3d80;
  font-weight: 800;
  border: 0px;   
}

.btn-orange:hover {
  transform: scale(1.03);   
}
.banner-franquias {
  width: 90%;
  height: 50%;
  margin-top: 130px;
  margin-left: 70px;
} 

.banner-franquias2 {
  width: 90%;
  height: 50%;
  margin-top: 145px;
  margin-left: 100px;
  border-radius: 40px;
}

.btn-orange-plus {
  width: 110%;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 30px;
  text-decoration: none;
  background-color: orange;
  color: #3a3d80;
  font-weight: 800;
  border: 0cm;

}
.btn-orange-plus:hover {
  transform: scale(1.02); 
}
.div-btn-rodape{
  margin-left: 115px;
}