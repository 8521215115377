.container-fundo-dashboard {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 15px;
  background: #3a3d80;
}

.container-body-dashboard {
  background-color: white;
  border-radius: 25px;
  width: 100%;
  height: 100%;
  margin: auto;
}

.banner-dashboard {
  width: 90%;
  height: 70%;
  margin-top: 130px;
  margin-left: 70px;
}

.btn_ipr {
  width: 180px;
  height: 50px !important;
}

.container-principal {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 15px;
  background: #3a3d80;

}

.caixa {
  max-width: 50%;
}

.conteudo {
  margin-top: 100px;
  align-content: flex-end;
}

.rodape {
  max-width: 90%;

  background-color: white;
  border-radius: 30px;
  min-width: 1292px !important;
  margin-left: 20px;
}

.btn-orange {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 30px;
  text-decoration: none;
  background-color: orange;
  color: #3a3d80;
  font-weight: 800;
  border: 0cm;
  margin-bottom: 50px;
}

.btn-orange-destaque {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 30px;
  text-decoration: none;
  background-color: orange;
  color: #3a3d80;
  font-weight: 800;
  border: 0cm;
  margin-bottom: 50px;
}

.banner {
  width: 1100px;
  height: 420px;
  margin-top: 100px;
  margin-left: 100px;
}

.banner2 {
  width: 1100px;
  height: 420px;
  margin-left: 100px;
}

.tela-mensagens {
  border-radius: 20px;
  background-color: white;
  margin: auto;
  align-items: center;


}