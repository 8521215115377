.lista-eventos {
  position: absolute;
  top: 80px;
  left: 50%;
  overflow-wrap: auto;
  background-color: rgb(255, 255, 255);
  z-index: 9;
}

.buton-listar-mes {
  position: absolute;
  top: 35px;
  left: 82%;
}

.linha-lista {
  height: 35px;
  align-items: center;
}

.buton-info {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 25px;
  max-width: 25px;
  margin: auto;
}

.img-plus {
  width: 13px;

}

.container-calend {
  max-height: 90vh;
  width: 100%;
}