.tam-nav {
    border-radius: 25px;
    max-width: 94%;
    margin-left: 35px;
}

.logo-franquead {
    max-height: 90px;
    border-radius: 10px;
}

.nav-item-btn {
    min-width: 125px !important;
}

.btn-sair {
    border-style: none;
    background-color: #fff;
}

.btn-white {
    width: 30%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
    border-radius: 30px;
    text-decoration: none;
    background-color: #3a3d80;
    color: rgba(253, 152, 21, 0.979);
    font-weight: 700;
}

.btn-white:hover {
    transform: scale(1.02);  
}

.btn-blue:hover {
    transform: scale(1.02);
}

.btn-blue {
    width: 30%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
    border-radius: 30px;
    text-decoration: none;
    background-color: #3a3d80;
    color: white;
    font-weight: 700;
}

.menu-aux{
    height: 43px;
    width: 120px;
    background-color: #3a3d80 !important;
    border-radius: 25px;   
}

.ul-menu:hover{
    background-color:rgb(236, 222, 222) !important;
}
.menu-aux:hover {
    transform: scale(1.03);
}