.buscainput{
    max-width: 600px !important;
    max-height: 38px;
}

.tabela-items_franqueados{
   
    border: 1px solid #d4d4d4;
    border-top: none;
    z-index: 99;      
    left: 0;
    right: 0;
    overflow-x: auto !important;
    overflow-y: auto !important;  
    max-height: 55vh;


}
.tabela-items_tabAlu div:hover{
    background-color: #e9e9e9;
}
.btn-aluno-imprimir{
    height: 38px !important;
}
.btn-aluno-novo{
    height: 38px !important;
}

.div-btns{
    max-width: 90%;
    margin-left: 150px;
}
.select-acoes-franqueados{
    max-width: 122px;
    font-size: small;
}
