.margem-top-col{
    margin-top: 120px;
}

.buscainput-col{
    max-width: 400px !important;
    max-height: 38px;
}

.tabela-items-tabCol{   
    border: 1px solid #d4d4d4;
    border-top: none;
    z-index: 99;      
    left: 0;
    right: 0;
    overflow-x: auto !important;
    overflow-y: auto !important; 
    max-height: 45vh;
}

.over{
    overflow-x: auto !important;
    overflow-y: auto !important; 
}

.tabela-items-tabCol div:hover{
    background-color: #e9e9e9;
}

.cnpj-col{
    width: 60%;
}

.select-acoes-col{
    max-width: 122px;
    font-size: small;
}