.fundo-img-aniversarios {
    width: 100%;
}

.fundo_img_aniver {
    position: relative;
    width: 104%;
    height: 450px;
    border-radius: 15px;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/franqueadora-ce93d.appspot.com/o/franqueadora%2Fimagens%2Ffundo.png?alt=media&token=ebdca570-c730-4504-a8bb-c7253a33871b);
}

.tab-funcao_col {
    width: 700px;
}

.tabela-modal {
    border: 1px solid #d4d4d4;
    border-top: none;
    z-index: 99;
    left: 0;
    right: 0;
    overflow-x: auto !important;
    overflow-y: auto !important;
    max-height: 210px;
    max-width: 98%;
}

.tabela-modal div:hover {
    background-color: #e9e9e9;
}