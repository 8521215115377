.sidebar-nc{
    background-color: rgb(229, 230, 231);
}
.cpf {
    max-width: 150px;
}
.dt-nasc-nc{
    max-width: 105px !important;
    
}
.cert-nasc-nc{
    max-width: 150px !important;
}
.sexo-fran{
    max-width: 150px !important;
}
.mensa-nc{
    min-width: 150px !important;
}

.blocoIcones {
    width: 135px;
    height: 28px;
    border-radius: 5px;
}

.blocoIcones input {
    float: left;
    width: 80%;
    height: 100%;
    border: none;
    border-radius: 5px;
    padding: 0 3px;
}

.blocoIcones button {
    float: left;
    width: 20%;
    height: 100%;
    background: #cccfd1;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}

.blocoIcones svg {
    width: 80%;
    height: 80%;  
}


.blocoIcones_alu {
    width: 125px;
    height: 28px;
    border-radius: 5px;
}

.blocoIcones_alu input {
    float: left;
    width: 100%;
    height: 95% !important;
    border: none;
    border-radius: 5px;
    padding: 0 3px;
}

.blocoIcones_alu button {
    float: left;
    width: 20%;
    height: 90%;
    background: #cccfd1;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}
 
.blocoIcones_aluno2 {
    width: 250px;
    height: 18px;
    border-radius: 5px;
}

.blocoIcones_aluno2 .dt_2 {
    float: left;
    width: 20%;
    height: 90%;
    background: #cccfd1;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}

.numero-cr{
   max-width: 80px;
}
.input-cep-cr{
    max-width: 100px;
}

.nome-cr{
    min-width: 800px;
}

.cidade-cr{
    min-width: 550px;
}

.imagem_foto-cr {
    width: 280px;
    height: 200px;
}

.dt-btn{
    max-width: 43px !important; 
 }
.img-condicao{
    max-width: 750px;
}