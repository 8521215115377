.fundo-dock{
    background-color: rgb(229, 230, 231);
}
.btn-foto{
    width: 120px;
}
.cpf {
    min-width: 170px;
}
.dt-nasc-fran{
    min-width: 100px !important;    
}
.dat-cad-fra {
    max-width: 170px !important;   
}
.cert-nasc-nc{
    max-width: 150px !important;
}
.sexo-fran{
   min-width: 170px !important;
}
.mensa-nc{
    min-width: 150px !important;
}
.dt_nac-btn{
    max-width: 43px !important; 
    min-height: 34px;
    background: #cccfd1;
 }
.numero-cr{
   max-width: 80px;
}
.input-cep-fran{
    max-width: 100px;
    min-height: 32px;
}
.btn-cep-fran{
    min-height: 32px !important;
}

.nome-cr{
    min-width: 800px;
}

.cidade-cr{
    min-width: 550px;
}

.imagem_foto-franqueados {
    max-width: 230px !important;
    max-height: 200px !important;
}

.dt-btn{
    max-width: 43px !important; 
 }
.div-btns2{
    margin-top: 175px;

    }
    .btn-franqueados{
        width: 120px;
    }   

