.container-fundo-col{
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 15px;
    background: #3a3d80;
  }
  
  .container-body-col{
    width:100%;
    background-color: white;
    border-radius: 30px; 
  }
  
  .img-card-col{
    width: 15rem;
    height: 15rem;
    box-shadow: 4px 4px 8px 1px #717175;
  }
  
  .coluna-aniversariantes-col{
    height: 650px;
    overflow-x: hidden;
    
  }
  .div-cards{
    max-height: 650px;
    overflow-x: auto;
    overflow-y: hidden;
    align-items: center;
    margin-top: 90px;
    justify-content: center;

  }
  
  