.container-fundo-cidades{
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 15px;
    background: #3a3d80;
  }
  
  .container-body-cidades{
    width:100%;
    background-color: white;
    border-radius: 30px; 
  }  

  .coluna-aniversariantes-cidades{
    height: 400px;
    overflow-y: auto;    
  }
  .div-cards-cid{
    margin-left: 10px;
    max-height: 650px;
    max-width: 98%;
    overflow-x: auto;
    align-items: center;
    margin-top: 90px;  
  }
  
  .div-cards-cid-center{
    margin-left: 10px;
    max-height: 650px;
    max-width: 98%;
    overflow-x: auto;
    align-items: center;
    margin-top: 90px;  
    justify-content: center;

  }
  
  .img-card-cidades{
    width: 24rem;
    height: 13rem;
    box-shadow: 4px 4px 8px 1px #717175;
  }
  
  
  