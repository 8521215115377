.tabela-funcao {
    max-width: 100%;
}

.titulo-tabela1 {
    max-width: 100%;
}

.tabela-funcao {

    border: 1px solid #d4d4d4;
    border-top: none;
    z-index: 99;
    left: 0;
    right: 0;
    overflow-x: auto !important;
    overflow-y: auto !important;
    max-height: 200px;
    max-width: 98%;
}

.tabela-funcao div:hover {
    background-color: #e9e9e9;
}

.tabela-calendario {
    border: 1px solid #d4d4d4;
    border-top: none;
    z-index: 99;
    left: 0;
    right: 0;
    overflow-x: auto !important;
    overflow-y: auto !important;
    max-height: 150px;
    max-width: 98%;
}
.tabela-calendario div:hover {
    background-color: #e9e9e9;
}