.container-fundo-col-contratos {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 15px;
  background: #3a3d80;
}

.container-tela-col-contratos {
  background-color: white;
  border-radius: 25px;
  width: 100%;
  height: 88vh;
}

.frame-contratos-2 {
  min-width: 1200px;
  min-height: 85vh;

}