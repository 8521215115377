.container-fundo-inalguracoes {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 15px;
  background: #3a3d80;
}

.container-body-inalguracoes {
  background-color: white;
  border-radius: 25px;
  width: 100%;
  height: 100%;
}

.calendario-inalguracoes {
  margin-top: 50px;
  max-height: 500px;
  overflow: auto;
  width: 90%;
}

.buton-inalguracoes-voltar {
  position: absolute;
  top: 20px;
  left:92%;
}
.h4-inalguracoes{
  position: absolute;
  top: 29px;
  left:25%;
}
.tam-calend{
  margin-top: 50px;
  margin-left: 60px;
  max-width: 100%;
  max-height: 40%px ;
  overflow-y: auto;

}


.buton-inalg-voltar {
  align-items: center;
  height: 80px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.buton-inalg-listar {
  align-items: center;
  height: 80px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}