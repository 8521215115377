.container-fundo-franqueados-contratos {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 15px;
  background: #3a3d80;
}

.container-body-projetos {
  background-color: white;
  border-radius: 25px;
  width: 100%;
  max-height: 95vh;
}

.frame-projetos {
  min-width: 180vh;
  min-height: 83vh;
}

.div-btn-id {
  height: 37px !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: -3px;
}

.btn-bi-search {
  height: 37px !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: -3px;

}

.campo-id-temp {
  width: 103px;
  max-height: 37px;
}