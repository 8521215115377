@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
    overflow-x: hidden;
    overflow-y: hidden;
    font-family: "Poppins";  
    background-color: #3a3d80;
}

select {
    border: 0px;
    background-color: #fff;
    cursor: pointer;
    width: 100%;
}

.no-data-found {
    height: 450px;
    padding-top: 220px;
}

.margem-top {
    margin-top: 100px;
}

.fundo-escuro {
    background-color: darkblue !important;
}
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-thumb{
    background-color: rgb(211, 182, 174);
}