.container-fundo-col-aut {
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 15px;
    background: #3a3d80;
  }
  
  .container-body-col-aut {
    background-color: white;
    border-radius: 25px;
    width: 100%;
    height: 538px;
  }

  .frame-autorizacoes{
    min-width: 1200px;
    min-height: 1200px;
}
