.card-franquia{
  max-width: 70px !important;
  min-width:70px !important;
  max-height: 70px !important;
  margin-bottom: 5px;
}

.container-body-fotos-geral{
  background-color: white;
  border-radius: 25px;
  width: 97%;
  max-height: 98vh;
  }

.div-select{
  width: 150px;
}

.btn-id{
  background-color:rgb(19, 165, 56);
  border:none;
  width: 30px;
  height: 100%;
  border-radius: 0px 10px 10px 0px;
}
.input-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.frame-pesq-foto{
  min-width: 98%;
  min-height: 83vh; 
}

.barra-lx{
  max-height: 560px;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb{
  background-color: rgb(211, 182, 174);
}

.coluna-img-foto{
  max-height: 78vh;
  flex-direction: column;
  overflow-y: auto;
}
.butonn-usar{
  width: 200px;
  margin-right: 25px;
}
.autocplt{
  background-color: blue;
}
