.container-fundo-geral {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 15px;
  background: #3a3d80;
}

.container-body-geral {
  background-color: white;
  border-radius: 25px;
  width: 100%;
  height: 100%;
}

.calendario-geral {
  overflow: auto;
  width: 90%;
  margin-left: 60px;

}

.buton-geral-voltar {
  align-items: center;
  height: 80px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.buton-geral-listar {
  align-items: center;
  height: 80px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.h4-geral {
  position: absolute;
  top: 29px;
  left: 25%;
}

.fc-daygrid-day-frame {
  cursor: pointer;
  overflow-x: visible;
}

.fc-daygrid-day-events {
  max-height: 80px !important;

}