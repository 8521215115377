* {
	margin: 0px; 
	padding: 0px; 
	box-sizing: border-box;
}
.img-logo{
  width: 330px;
}
.fund-login{
  background-color: rgb(2, 2, 75) !important;

}

.btn-enter{
  max-width: 250px;
}
.texto_input{
  font-family: Poppins;
  font-size: 13px;
  line-height: 1;
  color: #1a0404;

}
/*---------------------------------------------*/
input {
	outline: none;
	border: none;
}

/*//////////////////////////////////////////////////////////////////
[ login ]*/

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;  
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #beb8b8;

}

.wrap-login100 {
  width: 900px;
  background: #fff;
  border-radius: 65px;
  overflow: hidden;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 81px 90px 80px 95px;
}


.login100-form {
  width: 290px;
}


/*---------------------------------------------*/
.wrap-input100 {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 20px;
}

.input100 {
  font-family: Poppins;
  font-size: 15px;
  line-height: 1;
  color: #1a0404;

  display: block;
  width: 100%;
  background: #e6e6e6;
  height: 45px;
  border-radius: 25px;
  padding: 0 30px 0 68px;
  border-width: 1px; 
  border-style: solid; 
  border-color:  rgb(0, 0, 0);

}

.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
}

.login100-form-btn {
  font-family: Poppins;
  font-size: 15px;
  line-height: 1;
  color: #fff;

  width: 100%;
  height: 50px;
  border-radius: 30px 0px 30px 30px;
  background: #1003a3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  background: #4757e4;
}
