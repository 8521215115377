.container-fundo-col-contratos {
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 15px;
    background: #3a3d80;
  }
  
  .container-body-col-contalogos {
    background-color: white;
    border-radius: 25px;
    width: 100%;
    min-height: 88vh;
  }

.frame-catalogos {
  min-width: 1200px;
  min-height: 82vh;

}