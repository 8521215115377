.container-fundo-franqueado{
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 15px;
    background: #3a3d80;
  }
  
  .container-body-franqueado{
    width:100%;
    background-color: white;
    border-radius: 30px; 
  }

  .coluna-aniversariantes-franqueado{
    height: 400px;
    overflow-y: auto;    
  }
    
  .img-card-franqueado{
    width: 17rem;
    height: 16rem;
    box-shadow: 4px 4px 8px 1px #717175;
  }
  

  