.container-body-foto-cont {
  background-color: white;
  border-radius: 25px;
  width: 100%;
  max-height: 95vh;
}

.div-selec-cid {
  margin-left: 145px;
}

.container-fundo-foto-cont {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 15px;
  background: #3a3d80;
}

.frame-fotos-view {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.fotos-view {
  width: 98%;
  height: 100%;
  overflow: hidden;
}

.div-principal {
  height: 92%;
  overflow: hidden;
}

.card-franquia{
  max-width: 65px !important;
  min-width:65px !important;
  max-height: 65px !important;
  margin-bottom: 5px;
}

.div-select-pasta{
  width: 200px;
}

.btn-id{
  background-color:rgb(19, 165, 56);
  border:none;
  width: 30px;
  height: 100%;
  border-radius: 0px 10px 10px 0px;
}
.btn-id-select{
  background-color:rgb(19, 165, 56);
  border:none;
  width: 35px;
  height: 100%;
  border-radius: 0px 10px 10px 0px;
  margin-left: -10px; 
}

.input-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.frame-pesq{
  width: 1100px; 
  height: 560px; 
}

.barra-lx{
  max-height: 560px;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb{
  background-color: rgb(211, 182, 174);
}

.coluna-img{
  max-height: 93vh;
  flex-direction: column;
  overflow-y: auto;
}