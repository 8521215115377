.container-fundo-menu-col {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 15px;
  background: #3a3d80;
}

.container-body-menu-col {
  background-color: white;
  border-radius: 25px;
  width: 100%;
  height: 100%;
  margin: auto;
}

.btn-orange-tam {
  min-width: 180px;
}

.btn-orange-destaque {

  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 30px;
  text-decoration: none;
  background-color: orange;
  color: #3a3d80;
  font-weight: 800;
  border: 0cm;
  margin-bottom: 30px;
}

.banner-colaboradores {
  width: 90%;
  height: 50%;
  margin-top: 130px;
  margin-left: 100px;
}