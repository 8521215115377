.buscainput-fran {
    max-width: 600px !important;
    max-height: 38px;
    font-size: small;
}

.titulo-tabela1 {
    max-width: 100%;
}

.tabela-franquias {
    max-width: 100%;
}

.tabela-franquias {
    border: 1px solid #d4d4d4;
    border-top: none;
    z-index: 99;
    left: 0;
    right: 0;
    overflow-x: auto !important;
    overflow-y: auto !important;
    max-height: 50vh;
    max-width: 98%;
}

.tabela-franquias div:hover {
    background-color: #e9e9e9;
}

.btn-fran-imprimir {
    height: 38px !important;
}

.btn-fran-novo {
    height: 38px !important;
}

.cnpj {
    max-width: 84%;
    font-size: small;
}

.div_tipo {
    max-width: 10%;
    font-size: small;
}

.div_regiao {
    max-width: 12%;
    font-size: small;
}
.div_uf {
    max-width: 5%;
    font-size: small;
}
.select-acoes{
    max-height: 33px;
    font-size: small;
}
.fonte-small{
    font-size: small;
}
