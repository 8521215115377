.esconder{
    display: none;
}
.div-btn-franquias {
    margin-top: 35px;
}

.fundo_cad {
    background-color: rgb(229, 230, 231);
}

.dt_inalg-btn {
    max-width: 43px !important;
    min-height: 34px;
    background: #cccfd1;
}

.btn-cidade {
    background: #cccfd1;
    padding: 10px;
    border-radius: 8px;
    margin-left: -5px;
}

.blocoIcones_fran {
    width: 170px;
    height: 18px;
}

.blocoIconesFran-cad {
    width: 940px;
    height: 34px;
    border-radius: 5px;
}

.blocoIconesFran-cad input {
    float: left;
    width: 78%;
    height: 100%;
    border: none;
    border-radius: 5px;
    padding: 0 3px;
}

.blocoIconesFran-cad button {
    float: left;
    width: 3%;
    height: 100%;
    background: #cccfd1;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}

.blocoIconesFran-cad svg {
    width: 90%;
    height: 90%;
}

.blocoIconesCep-cad {
    width: 940px;
    height: 34px;
    border-radius: 5px;
}

.blocoIconesCep-cad input {
    float: left;
    width: 78%;
    height: 100%;
    border: none;
    border-radius: 5px;
    padding: 0 3px;
}

.blocoIconesCep-cad button {
    float: left;
    width: 3%;
    height: 100%;
    background: #cccfd1;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}

.blocoIconesCep-cad svg {
    width: 90%;
    height: 90%;
}
.div-end-cep{
    border: dashed;
    opacity: 0,5;
}
.input-cep-digitado{
    max-height: 37px; 
}

.buton-sair{
    width: 110px;
}


.inputFran {
    width: 170px;
    height: 18px;
}

.inputFran-cad {
    width: 700px;
    height: 34px;
    border-radius: 5px;
}

.inputFran-cad input {
    float: left;
    width: 78%;
    height: 100%;
    border: none;
    border-radius: 5px;
    padding: 0 3px;
}

.inputFran-cad button {
    float: left;
    width: 5%;
    height: 100%;
    background: #6b57db;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}

.inputFran-cad svg {
    width: 90%;
    height: 90%;
}
.btn-sair-modal-cidades{
    width: 20%;
    margin-right: 37px;
}

.autocomplete-cid-clear{
    position: absolute;
    top: 1px;
    border-radius: 3px;
    right: 5px;
    z-index: 2;
    border: none;    
 
    cursor: pointer;   
    background-color: #fcf9f9; 
}

.autocomplete-cid-img{
    height: 33px;
}

.autocomplete-cid{   
    position: relative;
    display: inline-block;
}
.autocomplete-cid-items{
    position: absolute;
    border: 1px solid #d4d4d4;
    border-top: none;
    z-index: 99;    
    top: 100%;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto; 
    max-height: 420px;
    background-color: #ffffff;
}
.autocomplete-cid-items > .autocomplete-item {
    padding: 5px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;  
    font-size: 13px;
    width: 400px;
}

.autocomplete-cid-items div:hover{
    background-color: #e9e9e9;
}

/*
.container-body-est-cont{
    background-color: white;
    border-radius: 25px;
    width: 100%;
    max-height: 95vh;
    }
  
   .div-selec-est{
    margin-left: 145px;   
   }
  
  .container-fundo-est-cont{
      width: 100%;
      height: 100vh;
      display: flex;
      padding: 15px;
      background: #3a3d80;
     
    } 
      */