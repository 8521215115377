.autocomplete-franquia{   
    position: relative;
    display: inline-block;
}
.autocomplete-franquia-items{
    position: absolute;
    border: 1px solid #d4d4d4;
    border-top: none;
    z-index: 99;    
    top: 100%;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto; 
    max-height: 420px;
}
.autocomplete-franquia-items > .autocomplete-item {
    padding: 5px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;  
    font-size: 13px;
    width: 400px;
}

.autocomplete-franquia-items div:hover{
    background-color: #e9e9e9;
}

.autocomplete-franquia-clear{
    position: absolute;
    top: 1px;
    border-radius: 3px;
    right: 5px;
    z-index: 2;
    border: none;    
    height: 94%;
    cursor: pointer;   
    background-color: #ffffff; 
}

.autocomplete-franquia-img{
    height: 28px;
}