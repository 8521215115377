.container-fundo-franquias{
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 15px;
    background: #3a3d80;
  }
  
  .container-body-franquias{
    width:100%;
    background-color: white;
    border-radius: 30px; 
  }

  .coluna-aniversariantes-franquias{
    height: 500px;
    width: 100%;
    overflow-y: auto;    
  }  

  .img-card-franquias{
    width: 16rem;
    height: 13rem;
    box-shadow: 4px 4px 8px 1px #717175;
  }  

  .card-body-franquias{
    width: 90%;
  }
  

  .div-cards-franquias{
    margin-left: 10px;
    max-height: 650px;
    max-width: 98%;
    overflow-x: auto;
    align-items: center;

  }

  
  .div-cards-center{
    margin-left: 10px;
    max-height: 650px;
    max-width: 98%;
    overflow-x: auto;
    align-items: center;
    margin-top: 90px;  
    justify-content: center;
  }

  .div-cards-start{
    margin-left: 10px;
    max-height: 650px;
    max-width: 98%;
    overflow-x: auto;
    align-items: center;
    margin-top: 90px;  
  }