.fundo-dock-col{
    background-color: rgb(229, 230, 231);
}

.imagem_foto-colaborador{
   max-width: 200px;
   border: none;
}
.img-col-seleciona{
    max-width: 200px;
}
.sexo-col{
    max-width: 120px;
}