.container-body-fran-cont{
    background-color: white;
    border-radius: 25px;
    width: 100%;
    max-height: 95vh;
    }
  
   .div-selec-cid{
    margin-left: 145px;   
   }
  
  .container-fundo-fran-cont{
      width: 100%;
      height: 100vh;
      display: flex;
      padding: 15px;
      background: #3a3d80;
    } 

    .frame-contratos-franquias{
      min-width: 1200px;
      min-height: 83vh;
    
  }
  .tabela-senhas-franquias {
    border: 1px solid #d4d4d4;
    z-index: 99;
    left: 0;
    right: 0;
    overflow-x: auto !important;
    overflow-y: auto !important;
    max-height: 50vh;
    max-width: 90%;
   
}
.tabela-senhas-franquias div:hover {
  background-color: #e9e9e9;
}