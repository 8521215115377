.container-body-fran-cont{
    background-color: white;
    border-radius: 25px;
    width: 100%;
    max-height: 95vh;
    }
  
   .div-selec-cid{
    margin-left: 145px;   
   }
  
  .container-fundo-fran-cont{
      width: 100%;
      height: 100vh;
      display: flex;
      padding: 15px;
      background: #3a3d80;
    } 

    .frame-contratos-franquias{
      min-width: 1200px;
      min-height: 83vh;
    
  }