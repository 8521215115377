.buton-convencao-voltar {
  position: absolute;
  top: 20px;
  left:92%;
}

.h4-convencao{
  position: absolute;
  top: 29px;
  left:25%;
}

.container-fundo-convencao {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 15px;
  background: #3a3d80;
}

.container-body-convencao {
  background-color: white;
  border-radius: 25px;
  width: 100%;
  height: 100%;
}

.calendario-convencao {
  margin-top: 50px;
  min-height: 300px;
  max-height: 500px;
  overflow: auto;
  width: 68%;
}