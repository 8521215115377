.buton-aniver-voltar {
  position: absolute;
  top: 20px;
  left:92%;
}
.container-fundo-calender-aniver {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 15px;
  background: #3a3d80;
}

.container-body-calender-aniver {
  background-color: white;
  border-radius: 25px;
  width: 100%;
  height: 100%;
}

.calendario-calender-aniver {
  margin-top: 50px;
  min-height: 300px;
  max-height: 500px;
  overflow: auto;
  width: 68%;
}

.h5-aniver{
  position: absolute;
  top: 29px;
  left:25%;
}

/*
.btn_ipr {
  width: 180px;
  height: 50px !important;
}

.caixa {
  max-width: 50%;
}

.conteudo {
  margin-top: 100px;
  align-content: flex-end;
}

.btn-orange {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 30px;
  text-decoration: none;
  background-color: orange;
  color: darkblue;
  font-weight: 800;
  border: 0cm;
  margin-bottom: 50px;
}

.btn-orange-destaque {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 30px;
  text-decoration: none;
  background-color: orange;
  color: darkblue;
  font-weight: 800;
  border: 0cm;
  margin-bottom: 50px;
}

.banner {
  width: 1100px;
  height: 420px;
  margin-top: 100px;
  margin-left: 100px;
}

.banner2 {
  width: 1100px;
  height: 420px;
  margin-left: 100px;
}
  */