.container-fundo-menu-calen {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 15px;
  background: #3a3d80;
}

.container-body-menu--calen {
  background-color: white;
  border-radius: 25px;
  width: 100%;
  height: 100%;
  margin: auto;
}

.btn-orange-plus {
  width: 110%;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 30px;
  text-decoration: none;
  background-color: orange;
  color: #3a3d80;
  font-weight: 800;
  border: 0cm;
  margin-bottom: 50px;  
}

.btn-orange-plus:hover {
  transform: scale(1.02); 
}

.banner-calendario {
  width: 90%;
  height: 50%;
  margin-top: 120px;
  margin-left: 100px;
}

.container_calander{
  width: 100%;
}